import cover from './olivercarter_cover.png';
import './App.css';
import Buttons from './buttons';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Dash">
        <img src={cover} className="App-logo" alt="logo" />
            <Buttons to='https://open.spotify.com/track/0hXzLExBOiaNbfDBEunGgq?si=6609c0f515a4486f' child='Spotify'></Buttons>
            <Buttons to='https://music.apple.com/us/album/start-a-commotion/1716456714?i=1716456715' child='Apple Music'></Buttons>
            <Buttons to='https://www.instagram.com/olivercarter.ca/' child='Instagram'></Buttons>
            <Buttons to='mailto:orgc@pm.me/' child='Bookings'></Buttons>
        </div>
      </header>
    </div>
  );
}

export default App;
