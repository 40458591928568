import './buttons.css'; 

function Buttons({to, child}) {
  return (
    <div>
      <a href={to} className="button-50" role="button">{child}</a>
    </div>
  );
}

export default Buttons;
